label {
	margin-bottom: 0 !important;
	margin-top: 0.5rem;
}

.color-red {
	color: #f44336;
}

.color-pink {
	color: #e91e63;
}

.color-purple {
	color: #9c27b0;
}

.color-indigo {
	color: #3f51b5;
}

.color-blue {
	color: #2196f3;
}
.color-green {
	color: #4caf50;
}
.color-yellow {
	color: #ffeb3b;
}
.color-amber {
	color: #ffc107;
}

.bg-amber {
	border-color: #c69500 !important;
	background-color: #ffeb3b !important;
}

.margin-2 {
	margin: 2px;
}

button.no-border {
	border: none;
	background: none;
	/*border-radius: 4px;*/
	margin: 3px;
}

div.aksi-container {
	display: grid;
}

.vertical-align {
	/*position: relative;*/
	/*top: 50%;*/
	/*transform: translateY(-50%);*/
	/*-ms-transform: translateY(-50%);*/
	/*-webkit-transform: translateY(-50%);*/
}

div.scrollHorizontal {
	background-color: transparent;
	overflow: auto;
	white-space: nowrap;
}

div.scrollHorizontalShow {
	background-color: transparent;
	overflow: scroll;
	white-space: nowrap;
}

/*div.scrollHorizontal a {*/
/*display: inline-block;*/
/*color: white;*/
/*text-align: center;*/
/*padding: 14px;*/
/*text-decoration: none;*/
/*}*/

div.scrollHorizontal a:hover {
	background-color: #777;
}

.react-datepicker-wrapper {
	display: block;
	width: 100%;
}

.react-datepicker__input-container {
	width: 100%;
}

.react-datepicker-ignore-onclickoutside {
	width: 100%;
}

.align-items-baseline {
	align-items: baseline;
	flex-direction: column;
}

.rdw-editor-main {
	border: 1px #ccc solid;
	padding: 0 10px;
}

.rdw-editor-main:focus {
	border: 1px #839acc solid;
}

.rdw-editor-main:active {
	border: 1px #839acc solid;
}

.public-DraftStyleDefault-block:focus .rdw-editor-main {
	border: 1px #839acc solid;
}
